html, body {
    text-rendering: optimizeSpeed;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    overflow-x: hidden;
}

body {
  margin: 0 auto;
  padding: 0;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.wb-text-primary {
  color: #f44336;
}

.ant-btn-link {
    color: #1890ff !important;
    background-color: transparent !important;
    border-color: transparent !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}
